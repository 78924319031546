<template>
  <!-- <base-section-alternating id="alternating-products"> -->
  <!-- <base-section-heading-alternating
      title="HVS Products Overview"
      class="px-2"
    >
    </base-section-heading-alternating> -->

  <v-container fluid>
    <v-row v-if="$vuetify.breakpoint.mdAndUp">
      <template v-for="(feature, i) in features">
        <v-col
          v-if="i === 0 || i === 1 || i === 4 || i === 5 || i === 8 || i === 9"
          id="color"
          class="my-10 py-0"
          :key="i"
          cols="12"
          sm="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="justify"
            horizontal
            customText="altProducts"
          />
        </v-col>
        <v-col v-else class="my-10 pa-0" :key="i" cols="12" sm="6">
          <base-avatar-card
            v-bind="feature"
            align="justify"
            horizontal
            customText="altProducts"
          />
        </v-col>
      </template>
    </v-row>
    <v-row v-else>
      <template v-for="(feature, j) in featuresMini">
        <v-col
          v-if="j === 0 || j === 2 || j === 4"
          id="color"
          :key="j"
          cols="12"
          sm="12"
        >
          <base-avatar-card
            v-bind="feature"
            align="justify"
            horizontal
            customText="altProducts"
          />
        </v-col>
        <v-col v-else :key="j" cols="12" sm="12">
          <base-avatar-card
            v-bind="feature"
            align="justify"
            horizontal
            customText="altProducts"
          />
        </v-col>
      </template>
    </v-row>
  </v-container>
  <!-- </base-section-alternating> -->
</template>

<script>
export default {
  name: "AlternatingProducts",
  computed: {
    featuresMini() {
      var tempArray = this.features;
      var indexes = [1, 2, 5, 6, 9, 10];
      tempArray = tempArray.filter(function (value, index) {
        return indexes.indexOf(index) == -1;
      });
      return tempArray;
    },
  },

  data: () => ({
    features: [
      {
        title: "MavenX",
        text: "MavenX is a flagship product of HVS which provides a suite of tools & features designed specifically for alternate asset managers. It provides a single source of truth for all deal-related data, enabling organizations to manage and track deals more efficiently with customizable deal stages, automated alerts, and real-time reporting.",
        link: "product",
      },
      {
        title: "",
        text: "",
        image: "mavenx-logo2",
      },
      {
        title: "",
        text: "",
        image: "tokenx-whitebg",
        invert: "yes",
      },
      {
        title: "TokenX",
        text: "TokenX offers cutting-edge Data Security as a Service (D-SaaS) solutions, enabling startups and other organizations to achieve data privacy and security standards with ease. Our innovative API-based platform provides a simple and elegant solution that eliminates the need for costly and time-consuming in-house development.",
        link: "product",
      },
      {
        title: "OneView",
        text: "OneView is an application management system which consolidates all the custom in-house apps, PowerBI reports, power apps etc. and gives you a single source of truth for all your applications and access mechanism. Ensure seamless employee access management and save licensing costs by integrating third party applications such as PowerBI.",
        link: "product",
      },
      {
        title: "",
        text: "",
        image: "oneview-logo",
        invert: "yes",
      },
      {
        title: "",
        text: "",
        image: "firstvault-whitebg",
      },
      {
        title: "FirstVault",
        text: "FirstVault is a security-first digital privacy vault which provides organizations a platform to store and share their sensitive documents securely alongwith other sensitive data such as Personally Identifiable Information (PII), login credentials, important documents and secrets using a 'Zero-Trust, Zero-Knowledge' security architecture.",
        link: "product",
      },
    ],
  }),
};
</script>
<style scoped>
#color {
  background-color: #ffffff;
  font-size: 1.9rem;
}

.v-col {
  font-size: 50px;
}
</style>
